.about-page {
    padding-top: 70px;
    padding-bottom: 140px;
    height: auto;
    .title {
        display: flex;
        flex-direction: column;
        padding: 80px 17.2vw 40px;
        margin-bottom: 20px;
        label {
            font-style: normal;
            font-weight: 700;
            font-size: 40px;
            color: #000000;
            margin-bottom: 15px;
        }
    }
    .intro {
        margin: 0 17.2vw;
        padding: 50px 40px;
        color: #000000;
        background-color: #d9d9d933;
        // background: linear-gradient(109.03deg, #3498db -6.19%, #003a70 102.05%);
        // opacity: 0.9;
        border-radius: 20px;
        h3 {
            font-size: 20px;
            margin-top: 52px;
            margin-bottom: 24px;
            font-weight: 700;
        }
    }
    .team-list {
        .team-member {
            display: flex;
            margin: 20px 0;

            i {
                display: block;
                color: #666;
                font-size: 13px;
            }

            h4 {
                font-size: 17px;
                font-weight: 700;
                margin-top: 4px;
                margin-bottom: 20px;
            }

            .team-member-photo {
                flex-shrink: 0;
                width: 160px;
                height: 160px;
                margin-right: 20px;
                border-radius: 50%;
                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .about-page {
        .title {
            display: flex;
            flex-direction: column;
            padding: 40px 8vw 20px;
            margin-bottom: 20px;
            label {
                font-style: normal;
                font-weight: 700;
                font-size: 25px;
                // color: #ffffff;
                margin-bottom: 5px;
            }
        }
        .intro {
            display: block;
            width: 80vw;
            margin: 0 auto;
            padding: 20px;
        }
    }
    
}

@media (max-width: 768px) {
    .team-member {
        flex-direction: column;
        align-items: center;

        .team-member-photo {
            margin: 0 0 20px 0;
        }
    }
}
