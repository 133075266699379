.footer-pages {
    padding-top: 70px;
    padding-bottom: 140px;
    height: auto;
    .title {
        display: flex;
        flex-direction: column;
        padding: 80px 17.2vw 40px;
        margin-bottom: 20px;
        label {
            font-style: normal;
            font-weight: 700;
            font-size: 40px;
            color: #000000;
            margin-bottom: 15px;
        }
    }
    .intro {
        margin: 0 17.2vw;
        padding: 50px 40px;
        color: #000000;
        background-color: #d9d9d933;
        // background: linear-gradient(109.03deg, #3498db -6.19%, #003a70 102.05%);
        opacity: 0.9;
        border-radius: 20px;
        h3 {
            font-weight: 700;
            margin-top: 40px;
            font-size: 20px;
        }
        li {
            margin: 12px 0;
            list-style: disc;
            padding-left: 4px;
            margin-left: 20px;
        }
        a {
            color: #003A70;
            text-decoration: underline;
        }
    }
}
@media (max-width: 768px) {
    .footer-pages {
        .title {
            display: flex;
            flex-direction: column;
            padding: 40px 8vw 20px;
            margin-bottom: 20px;
            label {
                font-style: normal;
                font-weight: 700;
                font-size: 25px;
                // color: #ffffff;
                margin-bottom: 5px;
            }
        }
        .intro {
            display: block;
            width: 80vw;
            margin: 0 auto;
            padding: 20px;
        }
    }
    
}

