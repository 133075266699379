.pc-dropzone {
    display: inline-flex;
    // justify-content: space-between;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    .left {
        flex: 1;
        height: 100%;
        display: inline-flex;
        flex-direction: column;
        // align-items: center;
        align-self: flex-start;
        justify-content: center;
        .drop {
            // height: 227px;
            width: 240px;
            padding: 20px 24px;
            background: #ffffff;
            border: 1px solid transparent;
            box-shadow: 0px 3px 4px transparent;
            border-radius: 10px;
            // margin-top: 30px;
            // margin-bottom: 30px;
            margin: 0 auto 24px;
            cursor: pointer;
            transition: all 0.3s;
            &:hover {
                background: rgba(52, 152, 219, 0.1);
                border: 1px solid #3498db;
                box-shadow: 0px 3px 4px rgba(52, 152, 219, 0.3);
            }
            div {
                display: flex;
                flex-direction: column;
                align-items: center;
                p {
                    font-family: "Alegreya Sans";
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 24px;
                    text-align: center;
                    color: #3498db;
                    margin-top: 0;
                    margin-bottom: 0;
                }
                img {
                    width: 137px;
                    height: 115px;
                    margin-top: 20px;
                }
            }
        }

        aside {
            width: 100%;
            div {
                border: 1px solid #d9d9d9;
                iframe {
                    // width: 54.1vw;
                    width: 100%;
                    height: 610px;
                    border: none;
                }
                img {
                    max-width: 780px;
                }
            }
        }
    }
    .right {
        height: 600px;
        align-self: center;
        .bill-data {
            // display: inline-flex;
            // flex-direction: column;
            // align-items: center;
            // justify-content: center;
            width: 440px;
            height: 100%;
            background: #ffffff;
            border: 1px solid #d9d9d9;
            border-radius: 20px;
            padding: 0 24px;
            margin-left: 24px;
            // overflow-y: scroll;
            // padding-bottom: 25px;
            position: relative;
        }
        .top {
            display: flex;
            position: sticky;
            top: 0;
            background: #fff;
            .onClick {
                border-bottom: 2px solid #3498db;
            }
        }
        .tabs {
            flex: 1;
            width: 100%;
            overflow-y: scroll;
            height: 520px;
            pre {
                margin: 0;
            }
        }
    }
}
@media screen and (min-width: 768px){
    .mobile-dropzone{
        display: none;
    }
}


@media screen and (max-width: 768px) {
    .mobile-dropzone {
        display: unset;
        background-color: #ffffff;
        .drop {
            width: 27.5vw;
            height: 140px;
            position: fixed;
            bottom: 20px;
            right: 5px;
            background-color: #ffffff;
            z-index: 10;
            border: 1px solid #f2f2f2;
            box-shadow: 0px 3px 4px rgba(52, 152, 219, 0.3);
            border-radius: 5px;
            padding: 5px;

            div {
                display: flex;
                flex-direction: column;
                align-items: center;
                p {
                    font-family: "Alegreya Sans";
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;
                    text-align: center;
                    color: #3498db;
                }
                img {
                    width: 80px;
                    height: 60px;
                }
            }
        }

        aside {
            margin: 0 3.5vw;
            div {
                border: 1px solid #d9d9d9;
                iframe {
                    width: 100%;
                    height: 200px;
                    border: none;
                }
                img {
                    max-width: 93vw;
                }
            }
        }
        .bill-data {
            margin: 0 3.5vw;
            padding: 10px;
            border: 1px solid #f2f2f2;
            margin-top: 10px;
            .top {
                display: flex;
                li {
                    button {
                        font-family: "Alegreya Sans";
                        font-size: 14px;
                        line-height: 17px;
                        text-align: center;
                        color: #000000;
                    }
                }
            }
            .tabs {
                ul {
                    li {
                        margin-top: 10px;
                        font-family: "Arial";
                        font-size: 12px;
                        line-height: 15px;
                    }
                }
            }
        }
    }
    .pc-dropzone{
        display: none;
    }
}

.tabs::-webkit-scrollbar {
    width: 0;  
    height: 0;
}

.MuiCircularProgress-root {
    display: block !important;
    margin: auto;
}

.tabs-ul {
    margin: 0;
    li {
        font-family: "Arial";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
        margin-bottom: 10px;
        p {
            margin: 5px 0 0 0;
            padding: 0;
            font-weight: 400;
        }
    }
}

.loading-bill {
    display: flex;
    align-items: center;
}

.upload-img {
    max-height: 580px;
    display: block;
    margin: 0 auto;
}
.pc-dropzone .left aside .img-div {
    border: none !important;
}