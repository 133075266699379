.contactPage {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 70px 18.7vw 140px 18.7vw;
    h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        color: #000000;
        margin-bottom: 15px;
        margin: 30px 0 10px 0;
    }
    h4 {
        font-family: "Arial";
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        color: #000000;
        margin: 10px 0 30px 0;
    }
    .form {
        width: 62.5vw;
        background: linear-gradient(109.03deg, #3498db -6.19%, #003a70 102.05%);
        border-radius: 20px;
        padding: 50px 32px;
        p {
            font-family: "Arial";
            font-weight: 700;
            font-size: 20px;
            line-height: 23px;
            color: #ffffff;
        }
        input {
            border: 2px solid #3498db;
            border-radius: 10px;
            height: 37px;
            outline: none;
            font-size: 18px;
            padding-left: 10px;
        }

        .first {
            display: flex;
            justify-content: space-between;
            li {
                input {
                    width: 32.9vw;
                }
                &:first-child {
                    input {
                        width: 22.5vw;
                    }
                }
            }
        }
        .company {
            input {
                width: 100%;
            }
        }
    }

    input {
        caret-color: #3498db;
        font-family: "Arial";
    }

    input:focus {
        border-color: #3498db !important;
        outline: 0;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px#7ebbe3;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px #7ebbe3;
    }

    textarea {
        border: 2px solid #3498db;
        border-radius: 10px;
        outline: none;
        font-size: 18px;
        padding-left: 10px;
        padding-top: 5px;
        height: 150px;
        resize: none;
        font-family: "Arial";
        width: 100%;
    }
    textarea:focus {
        border-color: #3498db !important;
        outline: 0;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px#7ebbe3;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px #7ebbe3;
    }

    button {
        width: 24.2vw;
        height: 60px;
        background: #3498db;
        border: 1px solid #ffffff;
        border-radius: 10px;
        font-family: "Gujarati Sangam MN";
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        color: #ffffff;
        text-transform: none;
        display: block;
        margin: 20px auto 0;
    }

    .ant-alert-message{
        color: #ffffff !important;
    }
}

@media screen and (min-width: 320px) and (max-width: 1200px) {
    .contactPage {
        padding: 90px 4vw 80px 4vw;
        h1 {
            font-size: 24px;
            margin: 10px;
        }
        h4 {
            font-size: 12px;
            margin: 0px 5px 5px 5px;
        }
        .form {
            width: 86vw;
            padding: 10px;
            input {
                border: 2px solid #3498db;
                border-radius: 10px;
                height: 28px;
                outline: none;
                font-size: 12px;
                padding-left: 5px;
                width: 70vw !important;
            }
            p {
                font-size: 14px;
                margin: 8px;
            }
            .first {
                display: unset;
            }

            textarea {
                width: 80vw !important;
                height: 80px !important;
            }
        }

        button {
            display: block;
            width: 40vw;
            height: 35px !important;
            font-size: 16px;
            line-height: 18px;
            margin: 10px auto;
        }
    }
}
