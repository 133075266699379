.home {
    padding-top: 10px;
    .home-content {
        display: flex;
        flex-direction: column;
    }
    .line {
        content: " ";
        width: 100%;
        height: 5px;
        background-color: #3498db;
    }
    .content-top {
        padding: 76px 120px 100px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        hgroup {
            h1 {
                font-family: "Alegreya Sans SC";
                font-weight: 800;
                font-size: 64px;
                line-height: 77px;
                color: #003a70;
                text-shadow: 0px 2px 4px rgba(52, 152, 219, 0.3);
                margin-bottom: 15px;
            }
            h4 {
                font-family: "Arial";
                font-weight: 400;
                font-size: 20px;
                line-height: 30px;
                color: #000000;
                a {
                    color: #003a70;
                    font-style: italic;
                }
            }

            .pc-buttons {
                margin-top: 60px;
                button {
                    width: 18.1vw;
                    height: 55px;
                    background: #3498db;
                    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
                    border-radius: 10px;
                    text-transform: none;
                    &:last-child {
                        width: 10.4vw;
                        height: 55px;
                        color: #000;
                        background: #dddddd;
                        border: 3px solid #ffffff;
                        border-radius: 10px;
                        margin-left: 58px;
                    }
                }
            }
        }
        img {
            width: 50%;
            max-width: 608px;
        }
    }
    .icons {
        padding: 5px 60px;
        display: flex;
        justify-content: space-around;
        margin-bottom: 75px;
        li {
            width: 15.5vw;
            height: 220px;
            .top {
                display: flex;
                align-items: center;
                margin-bottom: 25px;
                img {
                    height: 80px;
                    margin-right: 20px;
                }
                span {
                    font-family: "Alegreya Sans";
                    font-weight: 700;
                    font-size: 21px;
                    line-height: 29px;
                    text-align: center;
                    color: #000000;
                }
            }
            label {
                font-family: "Alegreya Sans";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: #979797;
            }
        }
    }
    .content-pc {
        width: 100%;
        // height: 1036px;
        background: linear-gradient(109.03deg, #3498db -6.19%, #003a70 102.05%);
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        label {
            font-family: "Alegreya Sans";
            font-size: 24px;
            line-height: 29px;
            color: #ffffff;
            margin-bottom: 38px;
        }
        .upload {
            width: 90vw;
            height: 750px;
            background-color: #ffffff;
            // overflow: auto;
            padding: 20px 24px;
            box-sizing: border-box;
        }
        .contact {
            // position: absolute;
            width: 151px;
            height: 55px;
            color: #000;
            background: #dddddd;
            border: 3px solid #ffffff;
            border-radius: 10px;
            // right: 5vw;
            // bottom: 26px;
        }
    }
    .content-pc-h1 {
        font-family: "Alegreya Sans";
        font-weight: 800;
        font-size: 48px;
        line-height: 58px;
        color: #ffffff;
    }
    
    .bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 66px 50px 70px;
        img {
            width: 17vw;
            z-index: 1;
        }
        li {
            box-sizing: border-box;
            &:first-child {
                font-family: "Alegreya Sans";
                font-weight: 900;
                font-size: 48px;
                line-height: 58px;
                color: #000000;
                border-bottom: 6px solid #3498db;
                padding-bottom: 25px;
            }
            display: flex;
            align-items: center;
            span {
                background-color: rgba(217, 217, 217, 0.2);
                align-self: center;
                font-family: "Arial";
                font-weight: 400;
                font-size: 16px;
                line-height: 30px;
                text-align: left;
                color: #000000;
                padding: 40px 20px;
                width: 60vw;
            }
            &:nth-child(2n + 2) {
                span {
                    margin-right: -80px;
                    padding-right: 50px;
                    text-align: right;
                }
            }
            &:nth-child(2n + 3) {
                span {
                    margin-left: -80px;
                    padding-left: 80px;
                    text-align: left;
                }
            }
        }
    }
}
@media screen and (max-width: 768px) {
    .home {
        .home-content {
            .content-top {
                height: 180px;
                padding: 5px 20px 10px 20px;
                background: url("../images/mobile_background.png") top right no-repeat;
                background-size: auto 100%;
                margin-bottom: 20px;
                hgroup {
                    h1 {
                        font-size: 32px;
                        line-height: 42px;
                        font-family: "Alegreya Sans SC";
                    }
                    h4 {
                        font-size: 12px;
                        line-height: 20px;
                    }
                }
                .pc-buttons {
                    display: none;
                }
                .pc-img {
                    display: none;
                }
            }
        }
        .home-line {
            display: none;
        }
        .content-pc {
            display: none;
        }

        .mobile-buttons {
            margin-top: 28px;
            padding-left: 50px;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            button {
                width: 49.6vw;
                height: 40px;
                font-weight: 800;
                font-size: 12px;
                line-height: 17px;
                box-shadow: 0px 1px 4px rgba(34, 17, 0, 0.3);
                border-radius: 5px;
                font-family: "Alegreya Sans";
                text-transform: none;
                &:last-child {
                    width: 26.9vw;
                    height: 35px;
                    background: #dddddd;
                    border: 1px solid #3498db;
                    border-radius: 5px;
                    margin-left: 0px;
                }
            }
        }
        .icons {
            float: left;
            display: flex;
            flex-flow: row wrap;
            padding: 0 3.5vw;
            justify-items: center;
            height: auto;
            li {
                width: 39.5vw;
                height: auto;
                border: 1px solid #f2f2f2;
                padding: 10px;
                .top {
                    margin-bottom: 10px;
                    img {
                        height: 55px;
                        margin-right: 15px;
                    }
                    span {
                        font-family: "Alegreya Sans";
                        font-style: normal;
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 17px;
                    }
                }
                label {
                    font-family: "Alegreya Sans";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 14px;
                    color: #979797;
                }

                &:nth-child(n + 3) {
                    margin-top: 10px;
                }
            }
        }

        .content-mobile {
            width: 100%;
        }
        .bottom {
            padding: 15px 3.5vw;
            li {
                margin: 0;
                margin-bottom: 14px;
                background: rgba(217, 217, 217, 0.2);
                padding: 0 20px;
                display: flex;
                width: 80vw;
                height: auto;
                span {
                    font-size: 12px;
                    line-height: 16px;
                    background: none;
                }
                img {
                    width: 27vw;
                }
                &:first-child {
                    width: auto;
                    font-size: 20px;
                    line-height: 38px;
                    border-bottom: 2px solid #3498db;
                    padding: 0;
                    padding-bottom: 0;
                    background: none;
                }
                &:nth-child(2n + 2) {
                    margin-left: 8vw;
                    span {
                        text-align: right;
                        padding: 10px 0px 10px 0px;
                    }
                    img {
                        margin-left: 78px;
                        margin-right: -42px;
                    }
                }
                &:nth-child(2n + 3) {
                    margin-right: 8vw;
                    span {
                        text-align: left;
                        padding: 20px 0px 20px 40px;
                    }
                    img {
                        margin-right: 50px;
                        margin-left: -50px;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .mobile_upload {
        display: none;
    }
    .mobile-buttons {
        display: none;
    }
}
