.homeWrap {
    .top-menu {
        position: sticky;
        top: 0;
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 11;
        padding: 0 70px;
        background: #ffffff;
        box-shadow: 0px 3px 4px rgba(151, 151, 151, 0.3);
        box-sizing: border-box; 
        a{
            text-decoration: none;
        }
        .left {
            display: flex;
            align-items: center;
            .home_link {
                font-family: 'Alegreya Sans';
                font-weight: 500;
                font-size: 32px;
                line-height: 38px;
                color: #003A70;
            }
        }
        .right {
            display: flex;
            align-items: center;
            justify-content: space-between;
            a {
                font-family: 'Alegreya Sans';
                font-size: 18px;
                line-height: 22px; 
                font-weight: 600;               
                color: #003A70;
                margin-left: 24px;
            }
            a:first-child {
                margin-left: 0;
            }
            
        }
    }

}

.ant-select-dropdown {
    border-radius: 10px;
    .ant-select-item-option {
        font-size: 15px;
        padding-top: 8px;
        padding-bottom: 8px;
    }
}

@media screen  and (max-width:768px) {
    .homeWrap {
        .top-menu {
            padding: 0 5vw;
            .left {
                .home_link {
                    font-size: 25px;
                }
            }
            .right {
                a {
                    font-size: 13px;
                }
            }
        }
    }
}


ol,
ul,
li {
    padding-left: 0;
    list-style: none;
}
